export const INTERFACE_BASE_ROUTE = '/interfaces';

export function getInterfacePageBuilderRoute(interfaceId: string, interfacePageSlug: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/builder/${interfacePageSlug}`;
}

export function getInterfacePreviewRoute(interfaceId: string, interfacePageSlug: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/preview/${interfacePageSlug}`;
}

export function getInterfacePageNavigationBuilderURL(interfaceId: string, interfacePageId: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/builder/page/${interfacePageId}`;
}

export function getInterfacePageNavigationPreviewURL(interfaceId: string, interfacePageId: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/preview/page/${interfacePageId}`;
}
